import Jimp from 'jimp';
import date from 'date-and-time';

// https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
/**
 *
 * @param {string} dataURI
 * @param {string} [fileName]
 * @return {Blob|File}
 */
export function dataURItoBlob(dataURI, fileName) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], {type: mimeString});
  if (fileName) {
    return new File([blob], fileName);
  }
  return blob;
}

export function dataFromFrame (frame) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = frame.imageSettings.width;
  canvas.height = frame.imageSettings.height;
  const imageData = ctx.createImageData(
    frame.imageSettings.width,
    frame.imageSettings.height
  );
  imageData.data.set(frame.imageData);
  ctx.putImageData(imageData, 0, 0);
  return canvas.toDataURL();
}

export async function resizeImage(dataURI, scale) {
  const blob = dataURItoBlob(dataURI);
  const image = await Jimp.read(await blob.arrayBuffer());
  image.scale(scale);
  image.quality(80);
  return await image.getBase64Async(Jimp.MIME_JPEG);
}

export async function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
      // returns data:<type>;base64,wL2dvYWwgbW9yZ...
    }
    reader.onerror = () => {
      reject(reader.error);
    }
    reader.onabort = () => {
      reject(new Error("Reader aborted while reading from file"))
    }
    reader.readAsDataURL(file);
  });

}

export function relativeTime (dateObj, now) {
  if (!now) {
    now = new Date();
  }
  const subtract = date.subtract(now, dateObj);
  const seconds = Math.round(subtract.toSeconds());
  const minutes = Math.round(subtract.toMinutes());
  const hours = Math.round(subtract.toHours());
  const days = Math.round(subtract.toDays());
  if (seconds < 60) {
    return `${seconds}\xa0second${seconds !== 1 ? 's' : ''}`;
  } else if (minutes < 60) {
    return `${minutes}\xa0minute${minutes !== 1 ? 's' : ''}`;
  } else if (hours < 48) {
    return `${hours}\xa0hour${hours !== 1 ? 's' : ''}`;
  } else {
    return `${days}\xa0day${days !== 1 ? 's' : ''}`;
  }
}

export function dateWithAgo (ms) {
  const dateTime = new Date(ms || 0);
  const now = new Date();
  const dateFormatted = date.format(dateTime, 'ddd\xa0MMM\xa0DD,\xa0Y\xa0HH:mm');
  if (now < dateTime) {
    const howLongUntil = relativeTime(now, dateTime);
    return `${dateFormatted}\xa0(in\xa0${howLongUntil})`
  }
  const howLongAgo = relativeTime(dateTime);
  return `${dateFormatted}\xa0(${howLongAgo}\xa0ago)`
}

export function dateNextTime (timeStr, ms) {
  const dateTime = new Date(ms);
  const dateOnly = date.format(dateTime, 'YYYY-MM-DD');
  const nextDate = date.parse(`${dateOnly} ${timeStr}`, 'YYYY-MM-DD HH:mm:ss');
  if (nextDate < dateTime) {
    return date.addDays(nextDate, 1);
  }
  return nextDate;
}

