import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {Form} from "react-bootstrap";
import Select from "react-select";

export function SelectDropDown(props) {
  const isInvalid = props.errors[props.prop];
  const invalidStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: 'max(16px, 1rem)',
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ?
        '#ddd' : isInvalid ?
          'red' : '#ddd',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : isInvalid ?
            'red' : '#ddd',
      }
    }),
    menu: (base) => ({
      ...base,
      zIndex: 2
    }),
  };

  const groupFind = (options, value) => {
    for (const group of options) {
      if (group.options) {
        const found = groupFind(group.options, value);
        if (found) return found;
      } else if (group.value && group.value.toString().toLowerCase() === value) {
        return group;
      }
    }
    return null;
  }

  const value = useMemo(() => {
    const formValue = (props.form[props.prop] ?? '').toString().toLowerCase();
    const options = props.options;
    if (props.form[props.prop] && options) {
      return groupFind(options, formValue);
    } else {
      return null;
    }
  }, [props.form, props.options, props.prop]);

  return (
    <>
      {props.label ? <Form.Label>{props.label}</Form.Label> : null}
      <Select
        custom
        as={Select}
        className={'w-100' + (isInvalid ? ' is-invalid' : '')}
        required
        isClearable
        isDisabled={props.disabled || false}
        onChange={(e) => {
          props.setForm(
            {[props.prop]: e ? e.value : ''}
          )
        }}
        value={value}
        placeholder={props.placeholder || ''}

        isSearchable={props.isSearchable || true}
        maxMenuHeight={200}
        options={props.options}
        styles={invalidStyles}
        isLoading={props.loading || false}
        components={props.components || undefined}
        filterOption={props.filterOption || undefined}
        formatGroupLabel={props.formatGroupLabel || undefined}
      />
      {props.errorMsg ? <div className="invalid-feedback">
        {props.errorMsg}
      </div> : null}
    </>
  );
}

SelectDropDown.propTypes = {
  label: PropTypes.string,
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  prop: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  options: PropTypes.array,
  errorMsg: PropTypes.string,
  loading: PropTypes.bool,
  components: PropTypes.object,
  filterOption: PropTypes.func,
  formatGroupLabel: PropTypes.func,
  disabled: PropTypes.bool,
};
