import React, {useCallback, useContext, useEffect, useState} from "react";
import {LayoutSplashScreen} from "../_metronic/layout";
import {firebaseContext} from "./AuthProvider";
import {getAction, getAgency, getCarrier, getPrompt} from "../api/options";
import {useModal} from "./ModalProvider";

export const optionContext = React.createContext();

const OptionProvider = (props) => {
  const {firebase, authUser} = useContext(firebaseContext);
  const [loading, setLoading] = useState(true);
  const [option, setOption] = useState({});
  const showModal = useModal();
  const refresh = useCallback(() => {
    (async () => {
      try {
        setLoading(true);
        if (authUser) {
          const token = await firebase.getToken();
          const promises = await Promise.all([
            getCarrier(token),
            getAction(token),
            getPrompt(token),
            getAgency(token)
          ]);
          const [carrier, action, prompt, agency] = promises.map((res) => res.data);
          setOption({
            carrier, action, prompt, agency
          });
        }
      } catch(e) {
        const {response} = e;
        showModal("An Error occurred.", response.data);
      } finally {
        setLoading(false);
      }
    })();
  }, [authUser, firebase, showModal]);
  useEffect(() => {
    refresh();
  }, [refresh, authUser]);
  if (loading) return <LayoutSplashScreen/>;
  return (
    <optionContext.Provider
      value={{option, refreshOption: refresh}}
    >
      {props.children}
    </optionContext.Provider>
  );
};

export default OptionProvider;
