import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './tools/reportWebVitals';
import AuthProvider from './provider/AuthProvider';
import "./styles/index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from "./_metronic/layout";
import ConfigProvider from "./provider/ConfigProvider";
import {ModalContextProvider} from "./provider/ModalProvider";
import OptionProvider from "./provider/OptionProvider";

ReactDOM.render(
  <MetronicLayoutProvider>
    <MetronicSubheaderProvider>
      <MetronicSplashScreenProvider>
        <ConfigProvider>
          <AuthProvider>
            <ModalContextProvider>
              <OptionProvider>
                <App />
              </OptionProvider>
            </ModalContextProvider>
          </AuthProvider>
        </ConfigProvider>
      </MetronicSplashScreenProvider>
    </MetronicSubheaderProvider>
  </MetronicLayoutProvider>,
  document.getElementById('root')
);

reportWebVitals();
