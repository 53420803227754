import {SelectDropDown} from '../form/SelectDropDown';
import CarrierIcon from './CarrierIcon';
import React, {useCallback, useContext, useMemo} from 'react';
import {optionContext} from '../../provider/OptionProvider';
import {components} from 'react-select';

// https://github.com/JedWatson/react-select/pull/3873
// cant do nested groups until this is merged
const MenuList = (props) => {
  const {children, ...rest} = props;
  const groupedOptions = useMemo(() => {
    const sections = [];
    if (!Array.isArray(children)) {
      return null;
    }
    for (const child of children) {
      const section = child.props.data.section;
      const agencyName = child.props.data.agencyName;
      let existingSection = sections.find(s => s.label === section);
      if (!existingSection) {
        existingSection = {label: section, children: []}
        sections.push(existingSection);
      }
      let existingAgency = existingSection.children.find(a => a.label === agencyName);
      if (!existingAgency) {
        existingAgency = {label: agencyName, children: []}
        existingSection.children.push(existingAgency);
      }
      existingAgency.children.push(child);
    }
    return sections;
  }, [children]);

  return <components.MenuList {...rest} >
    {Array.isArray(groupedOptions) ? groupedOptions.map(({label, children}) => {
      const sectionName = label;
      return <div key={sectionName}>
        <div className={'shadow p-3 text-dark-50 bg-secondary'} style={{
          zIndex: 3,
          position: 'sticky',
          top: '-5px',
        }}><CarrierIcon section={sectionName}/>&nbsp;{sectionName}</div>
        {children.map(({label, children}) => {
          const showAgencyLabel = children.length > 1 //|| label.length < 17;
          const agencyName = label;
          return <div key={agencyName}>
            {showAgencyLabel ? <div className={'mb-n6 w-100'} style={{
              zIndex: 4,
              position: 'absolute',
              height: `${35 * (children.length - 1)}px`,
              pointerEvents: 'none',
            }}><div
              className={`bg-transperant text-right p-3 pl-6 pr-3 text-dark-50`}
              style={{
                position: 'sticky',
                top: '-5px',
              }}><b >{agencyName}</b></div></div> : null}
            <div className={`bg-white shadow-lg`}>
              {children}
            </div>
          </div>
        })}
      </div>
    }) : children}
  </components.MenuList>;
}

export default function CarrierSelect (props) {
  const {form, setForm} = props;

  const filterAgency = false;
  const {option} = useContext(optionContext);
  const {carrier, agency} = option;
  const carrierWithAgency = useMemo(() => {
    return carrier.map(c => {
      const carrierAgency = agency.find(a => a.value === c.agency);
      const agencyName = (carrierAgency || {}).label || '';
      return {...c, agencyName};
    }).sort((first, second) => first.sort_order - second.sort_order);

  }, [carrier, agency]);

  const getCarrier = useCallback((carrier_id) => {
    return carrier.find(c => c.value === carrier_id);
  }, [carrier]);

  const selectedCarrier = useMemo(() => {
    return getCarrier(form.carrier_id) || {}
  }, [getCarrier, form]);

  const loading = carrierWithAgency.length === 0;

  return <SelectDropDown
        form={form}
        setForm={obj => {
          obj.agency_id = (getCarrier(obj.carrier_id) || {}).agency;
          setForm(obj);
        }}
        prop={"carrier_id"}
        errors={{carrier_id: !loading && (!form.carrier_id || !selectedCarrier)}}
        placeholder={"Please select Unit/Facility"}
        isSearchable={true}
        options={carrierWithAgency}
        loading={loading}
        components={{MenuList}}
        filterOption={(option, inputValue) => {
          if (filterAgency && option.agency !== form.agency_id) {
            return false;
          }
          const input = inputValue.toLowerCase();
          const label = option.label.toLowerCase();
          const agencyName = (option.data.agencyName || '').toLowerCase();
          return label.includes(input) || agencyName.includes(input);
        }}/>

}
