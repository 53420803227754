import {initializeApp} from 'firebase/app';
import date from 'date-and-time';
import {getAuth, sendSignInLinkToEmail, signInWithPhoneNumber, isSignInWithEmailLink, signInWithEmailLink} from "firebase/auth";

class Firebase {
  #signInTime;

  constructor(config) {
    const app = initializeApp(config);
    this.auth = getAuth(app);
  }

  loggedIn = () => {
    return !!this.auth.currentUser;
  }
  
  #clearSignInTime = (res) => {
    localStorage.removeItem('sign_in_time');
    this.#signInTime = undefined;
    return Promise.resolve(res);
  }

  #setSignInTime = (res) => {
    const now = (new Date()).getTime();
    localStorage.setItem('sign_in_time', `${now}`);
    this.#signInTime = now;
    return Promise.resolve(res);
  }

  getSignInTime = () => {
    const timeNumberString = localStorage.getItem('sign_in_time');
    const timeParsed = parseInt(timeNumberString);
    if (isNaN(timeParsed)) {
      if(!this.loggedIn()) return undefined;
      const now = (new Date()).getTime();
      localStorage.setItem('sign_in_time', `${now}`);
      this.#signInTime = now;
    } else {
      this.#signInTime = timeParsed;
    }
    return this.#signInTime;
  }

  getToken = (refresh = false) => {
    if (!this.auth.currentUser) return Promise.resolve(null);
    if (!this.lastRefresh) this.lastRefresh = new Date();
    const forceRefresh = refresh || new Date() > date.addHours(this.lastRefresh, 1);
    if (forceRefresh) this.lastRefresh = new Date();
    return new Promise(res => {
      this.auth.currentUser.getIdToken(forceRefresh).then(function(idToken) {
        res(idToken);
      }).catch(() => {res(null)});
    });
  }

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password).then(this.#setSignInTime);;

  signInWithPhoneNumber = (phone) => {
    const appVerifier = window.recaptchaVerifier;
    return signInWithPhoneNumber(this.auth, phone, appVerifier).then(this.#setSignInTime);;
  }

  sendSignInLinkToEmail = (email) => {
    console.log(window.location.origin + window.location.pathname)
    const actionCodeSettings = {
      url: window.location.origin + window.location.pathname,
      handleCodeInApp: true,
    }
    window.localStorage.setItem('emailForSignIn', email);
    return sendSignInLinkToEmail(this.auth, email, actionCodeSettings);
  }

  signInWithEmailLink = () => {
    let email = window.localStorage.getItem('emailForSignIn');
    if (email && isSignInWithEmailLink(this.auth, window.location.href)) {
      return signInWithEmailLink(this.auth, email, window.location.href)
        .then((result) => {
          window.localStorage.removeItem('emailForSignIn');
          return result;
        }).then(this.#setSignInTime);
    }
  }

  signOut = () => {
    return this.auth.signOut().then(this.#clearSignInTime).catch(console.log);
  }
}

export default Firebase;