import {useCallback, useEffect, useState} from "react";

const copyThenApplyObj = (form, obj, prop) => {
  const output = {};
  Object.keys(form).forEach(k => output[k] = form[k]);
  if (typeof prop == 'string') {
    Object.keys(obj).forEach(k => output[prop][k] = obj[k]);
  } else {
    Object.keys(obj).forEach(k => output[k] = obj[k]);
  }
  return output;
}

export default function useForm (initialForm) {
  if (initialForm && typeof initialForm !== "object") throw new TypeError("Initial form must be an object.");
  const [form, replace] = useState(initialForm || {});
  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(undefined);
  const [joiError, setJoiError] = useState(undefined);
  const [isValid, setValid] = useState(true);

  useEffect(() => {
    if (joiError) {
      const errorObject = {};
      joiError.details.forEach((error) => {
        const name = error.context.key;
        errorObject[name] = error.message;
      })
      setError(errorObject);
    } else {
      setError(undefined);
    }

  }, [joiError]);

  useEffect(() => {
    setValid(!error || Object.keys(error).length === 0);
  }, [error])

  const clearValue = useCallback((names) => {
    replace((prev) => {
      const output = {};
      if (!Array.isArray(names)) {
        names = [names];
      }
      for (const name of names) {
        if (typeof prev[name] !== 'undefined') {
          delete prev[name];
        }
      }
      Object.assign(output, prev);
      return output;
    });
  }, [replace]);
  const setForm = useCallback((obj, prop) => {
    setDirty(true);
    replace(prevForm => {
      return copyThenApplyObj(prevForm,obj,prop);
    });
  }, [setDirty, replace]);
  const replaceForm = useCallback((obj) => {
    setDirty(false);
    replace(obj);
  }, []);
  return [form, setForm, replaceForm, dirty, error, setError, setJoiError, isValid, clearValue];
}
