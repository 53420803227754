import {useCallback, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {self} from "../api/user";
import {firebaseContext} from "../provider/AuthProvider";

export default function useUser () {
  const [user, setUser] = useState({});
  const [exists, setExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const {firebase, authUser} = useContext(firebaseContext);
  const history = useHistory();
  const refresh = useCallback(() => {
    if (authUser) {
      setLoading(true);
      firebase.getToken().then(token => {
        return self(token)
      }).then((response) => {
        const {data} = response;
        setExists(true);
        setUser({...data});
      }).catch(e => {
        setExists(false);
        const {response} = e;
        if (response.status === 403) {
          history.push('/user')
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [authUser, firebase, history]);
  useEffect(() => {
    refresh();
  }, [authUser, refresh]);
  return [user, exists, refresh, loading];
}

