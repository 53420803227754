import React, {useContext, useEffect} from "react";
import {MaterialThemeProvider} from "./_metronic/layout";
import Routes from "./routes/Routes";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import {configContext} from './provider/ConfigProvider';

function App() {
  const config = useContext(configContext);
  const auth = getAuth();
  const captchaRef = React.useRef(null);
  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(captchaRef.current, {
      'size': 'invisible',
      'callback': function () {},
      'expired-callback': () => {}
    }, auth);
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }, [auth]);

  useEffect(() => {
    document.title = config.client.page_title;
  }, [config]);

  return (
    <MaterialThemeProvider>
      <div id="recaptcha-container" ref={captchaRef}/>
      <Routes/>
    </MaterialThemeProvider>
  );
}
export default App;