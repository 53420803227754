// copied from metronic/components
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useContext, useMemo} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
//import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_metronic/layout";
import { UserProfileDropdown } from "./UserProfileDropdown";
import {userContext} from "../../provider/UserProvider";
import {toAbsoluteUrl} from '../../_metronic/_helpers';
import SVG from "react-inlinesvg";

export function QuickUserToggler() {
  //const { user } = useSelector((state) => state.auth, shallowEqual);
  const {user, userExists, loadingUser} = useContext(userContext);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  if (!userExists) return null;
  if (loadingUser) return <div className="topbar-item">
    <div
      className={
        "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      }
    >
      <span className="text-muted font-weight-bold font-size-base d-md-inline mr-1">
        Loading
      </span>
    </div>
  </div>;

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                Hi,
              </span>
              <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                {user.fullname}
              </span>
              {/*<span className="symbol symbol-35 symbol-light-success">
                <span className="symbol-label font-size-h5 font-weight-bold">
                  {(user.fullname || " ")[0]}
                </span>
              </span>*/}
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
