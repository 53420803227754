import {toAbsoluteUrl} from "../routes/Routes";
import axios from "axios";

export async function self (firebase_token) {
  return axios({
    method: 'get',
    url: toAbsoluteUrl('/api/self'),
    headers: {'authorization': `Bearer ${firebase_token}`}
  });
}

// TODO user form submission

export async function create (firebase_token, user) {
  return axios({
    method: 'post',
    url: toAbsoluteUrl('/api/user'),
    headers: {'authorization': `Bearer ${firebase_token}`},
    data: user
  });
}

export async function update (firebase_token, user) {
  const { id } = user;
  return axios({
    method: 'patch',
    url: toAbsoluteUrl(`/api/user/${id}`),
    headers: {'authorization': `Bearer ${firebase_token}`},
    data: user
  });
}
