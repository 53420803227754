import React, {useState} from "react";
import useInterval from "../../hooks/useInterval";

export default function Ellipsis () {
  const min = 1;
  const max = 3;
  const char = ".";

  const setText = (c, l) => {
    let text = "";
    for (let i = 0; i < l; i++) {
      text += c;
    }
    return text;
  }

  const [count, setCount] = useState(max);
  useInterval(() => {
    setCount(prev => {
      if (prev < max) return prev + 1;
      else return min;
    })
  }, 150)
  return <>{setText(char, count)}</>
}