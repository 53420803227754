import Joi from 'joi';
import {regex} from '../Format';

const carrier_id = (carriers) => {
  const schema = Joi.number().integer().required();
  return Array.isArray(carriers) ? schema.valid(...carriers.map(carrier => carrier.value)) : schema;
}
const agency_id = (agencies) => {
  const schema = Joi.number().integer().required();
  return Array.isArray(agencies) ? schema.valid(...agencies.map(agency => agency.value)) : schema;
}

export const text = (name) => {
  return Joi.string().pattern(regex[name]);
}

export const user = (carriers, agencies) => {
  return Joi.object({
    id: Joi.number().integer(),
    uid: Joi.string().required(),
    last_name: Joi.string().pattern(regex.name).required(),
    first_name: Joi.string().pattern(regex.name),
    // email and sms schemas allow one or the other
    // https://stackoverflow.com/a/61212051
    // this way: empty string, null, undefined will count as an empty value
    email: text('email').allow('', null),
    sms: Joi.when('email', {
      is: Joi.string().valid('', null).optional(),
      then: text('phone').required(),
      otherwise: Joi.string().valid('', null)
    }),
    carrier_id: carrier_id(carriers),
    agency_id: agency_id(agencies),
    created_at: Joi.any(),
    updated_at: Joi.any()
  }).or('email', 'sms').required();
}

export const example = {
  agency_id: 23,
  carrier_id: 3,
  created_at: "2021-08-18T22:07:12.000Z",
  email: "null",
  first_name: "Emma",
  id: 6,
  last_name: "Zahn",
  sms: "210-309-0374",
  uid: "0M789wieAUM8l1wyeFJA33cQWCR2",
  updated_at: "2021-10-20T19:19:04.000Z",
}
