import React, {useContext, useEffect, useState, useMemo} from "react";
import {configContext} from "./ConfigProvider";
import Firebase from "../firebase/Firebase";
import {LayoutSplashScreen} from "../_metronic/layout";
import AutomaticLogout from '../components/auth/AutomaticLogout';

export const firebaseContext = React.createContext();

const AuthProvider = (props) => {
  const config = useContext(configContext);
  const firebase = new Firebase(config.client.firebase);
  // false means the auth status is unknown
  const [authUser, setAuthUser] = useState(false);
  useEffect(() => {
    const unlisten = firebase.auth.onAuthStateChanged(
      authUser => {
        authUser
          ? setAuthUser(authUser)
          : setAuthUser(null);
      },
    );
    return () => {
      unlisten();
    }
  });

  const idleTimer = useMemo(() => {
    return (config.client || {}).idle_timer || {};
  }, [config]);

  useEffect(() => {
    if (authUser === null) {
      firebase.signInWithEmailLink();
    }
  }, [authUser, firebase]);

  if (authUser === false) return <LayoutSplashScreen/>
  return (
    <firebaseContext.Provider
      value={{firebase, authUser}}
    >
      <AutomaticLogout
        idleTimeout={parseInt(idleTimer.idle)}
        promptTimeout={parseInt(idleTimer.prompt)}
        signInTimeout={parseInt(idleTimer.signIn)}
      />
      {props.children}
    </firebaseContext.Provider>
  );
};

export default AuthProvider;
