import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {LayoutSplashScreen} from "../_metronic/layout";
export const configContext = React.createContext();

const ConfigProvider = ({children}) => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  const loadConfig = useCallback(() => {
    setLoading(true);
    axios.get('config.json').then(res => {
      const config = {};
      config.client = res.data;
      config.client.clientTime = new Date();
      config.client.serverTime = new Date(config.client.serverTime);
      setConfig(config);
      localStorage.setItem('baseUrl', config.client.public_url);
    }).catch(error => {
      console.log(error);
      setFailed(true);
    }).finally(() => {
      setLoading(false);
    });
  }, [setConfig, setFailed, setLoading]);

  useEffect(() => {
    loadConfig();
  }, [loadConfig]);

  return <>
    <LayoutSplashScreen visible={loading}/>
    {!failed && !loading ? <configContext.Provider value={config}>{children}</configContext.Provider> : null}
    {failed && !loading ? <>Failed to load configuration. Please reload the page.</> : null}
  </>

}

export default ConfigProvider;
