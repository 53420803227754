import React, {useContext} from 'react';
import {Redirect, Route} from "react-router-dom";
import PropTypes from "prop-types";
import {Spinner} from "react-bootstrap";
import {firebaseContext} from "../provider/AuthProvider";
import {Layout} from "../components/layout/Layout"
import UserProvider from "../provider/UserProvider";

export default function PrivateRoute ({children, ...rest}) {
  let {authUser} = useContext(firebaseContext);

  if (authUser === false) {
    return <Spinner  animation={'border'}/>;
  }

  return <Route
    {...rest}
    render={({ location }) =>
      authUser ? (
        <UserProvider>
          <Layout>
            {children}
          </Layout>
        </UserProvider>
      ) : (
        <Redirect
          to={{
            pathname: "/auth",
            state: { from: location }
          }}
        />
      )
    }
  />
}
PrivateRoute.propTypes = {
  children: PropTypes.node
}
