export const regex = {
  email: /^[a-zA-Z0-9._%+-]+[@][a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phone: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
  name: /^[A-Za-zÀ-ÖØ-öø-ÿ/.' ]+$/,
  notes: /^[ -~À-ÖØ-öø-ÿ']+$/,
  barcode: {
    product_unitnumber: /^[a-zA-Z0-9]{14,16}$/,
    type_id: /^[a-zA-Z0-9]{4,6}$/,
    donor_number: /^[a-zA-Z0-9]{8,10}$/,
    expiration: /^[a-zA-Z0-9]{10,12}$/
  },
  rawBarcode: {
    product_unitnumber: /^[a-zA-Z0-9=%&<>]{16}$/,
    type_id: /^[a-zA-Z0-9=%&<>]{6}$/,
    donor_number: /^[a-zA-Z0-9=%&<>]{10}$/,
    expiration: /^[a-zA-Z0-9=%&<>]{12}$/
  },
}

/**
 *
 * @param {string} format The format's name
 * @param {any} value The value to check
 * @returns {boolean|string} False if the value is of valid format. String with the reason if invalid.
 */
export function isInvalid (format, value) {
  // completly skip the other steps
  // if value is null or undefined
  if (typeof value === 'undefined' || value === null) {
    return `cannot validate undefined or null value.`
  }

  switch(format) {
  case 'number':
    return typeof value === format ? false : `improper type. should be: ${format}.`;
  default:
    if (typeof value !== "string") return `improper type. should be string.`;
    const names = format.split('.');
    let _regex = regex;
    while (names.length > 0) {
      const newObj = _regex[names.shift()];
      if (typeof newObj === "object" || typeof newObj === "string")
        _regex = newObj;
      else
        return `invalid format. missing format: ${format}.`

    }
    return _regex.test(value) ? false : `invalid format. should be: ${format}.`;
  }
}

/**
 * Gets the last ten numbers from a phone number
 * +12105551234 >> 2105551234
 * 123-456-7890 >> 1234567890
 *
 * @param {string} phone
 * @returns {string}
 */
function getLastTenDigits (phone) {
  if (typeof phone !== "string") throw new TypeError("Phone number must be string.");
  let tenDigits = "";
  for (let i = 0; i < phone.length && tenDigits.length < 10; i++) {
    const digit = phone[phone.length - 1 - i];
    if (/[0-9]/.test(digit)) tenDigits = digit + tenDigits;
  }
  return tenDigits;
}

/**
 * Converts the last ten digits into a phone number with dashes
 * +12105551234 >> 210-555-1234
 * (123) 456-7890 >> 123-456-7890
 *
 * @param {string} phone
 * @returns {string}
 */
export function phoneTenDigit (phone) {
  const t = getLastTenDigits(phone);
  const areaCode = t.substr(0,3);
  const phoneNumber = `${t.substr(3,3)}-${t.substr(6,4)}`;
  return `${areaCode}-${phoneNumber}`;
}

/**
 * Firebase requires number with a country code.
 * 2105551234 >> +12105551234
 * (123) 456-7890 >> +11234567890
 *
 * @param {string} phone
 * @param {string} [code="+1"] By default the country code is the U.S. +1
 * @returns {string}
 */
export function phoneWithCountryCode (phone, code) {
  if (typeof code !== "string") code = "+1";
  const t = getLastTenDigits(phone);
  return `${code}${t}`;
}
