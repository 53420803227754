import React, {Suspense, lazy} from 'react';
import {Route, BrowserRouter, Switch, Redirect} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import {LayoutSplashScreen} from "../_metronic/layout";

const Auth = lazy(() =>
  import("../pages/Auth")
);
const User = lazy(() =>
  import("../pages/User")
);
const Action = lazy(() =>
  import("../pages/Action")
);

const routes=[{
  path: '/auth',
  children: <Auth/>
}, {
  path: '/user',
  children: <User/>,
  loginRequired: true
}, {
  path: '/action',
  children: <Action/>,
  loginRequired: true
}, {
  path: '/*',
  children: <Redirect to={'/action'}/>
}];

const routeComponents = routes.map((obj, index) => {
  const {path, children, loginRequired} = obj;
  if (loginRequired) return <PrivateRoute path={path} key={index}>{children}</PrivateRoute>
  return <Route path={path} key={index}>{children}</Route>;
});

export function toAbsoluteUrl (pathname) {
  let baseUrl = localStorage.getItem('baseUrl') || process.env.CLIENT_PUBLIC_URL || '/';
  if (baseUrl === "%CLIENT_PUBLIC_URL%") baseUrl = '/';
  if (typeof pathname !== "string" || pathname.length === 0) {
    throw TypeError("pathname must be a non empty string");
  }
  if (pathname[0] === '/') {
    pathname = pathname.slice(1);
  }
  //console.log(baseUrl, pathname);
  return baseUrl + pathname;
}

export default function Routes () {
  return <BrowserRouter basename={toAbsoluteUrl('/')}>
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>{routeComponents}</Switch>
    </Suspense>
  </BrowserRouter>
}
