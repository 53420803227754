import React, {useCallback, useMemo, useContext, useEffect, useState} from "react";
import {Button, Modal, Spinner, Form} from "react-bootstrap";
import {userContext} from '../../provider/UserProvider';
import {optionContext} from '../../provider/OptionProvider';
import useForm from '../../hooks/useForm';
import {firebaseContext} from '../../provider/AuthProvider';
import {update} from '../../api/user';
import useIsMounted from '../../hooks/useIsMounted';
import {user as userSchema} from '../../../common/Schema/User';
import Ellipsis from '../TextAnimation/Ellipsis';
import CarrierIcon from '../carrier/CarrierIcon';
import CarrierSelect from '../carrier/CarrierSelect';
import {dateNextTime, dateWithAgo, relativeTime} from '../../tools/data-convert';
import useInterval from '../../hooks/useInterval';
import date from 'date-and-time';
import Flash from '../TextAnimation/Flash';

export default function UserCarrierModal ({
  variant = 'light'
}) {

  const timerKey = 'carrier_last_set';

  const [prompt, setPrompt] = useState(false);
  const [form, setForm, replaceForm, isDirty] = useForm({});
  const [loading, setLoading] = useState(false);
  const [saveMessage, setMessage] = useState('');
  const isMounted = useIsMounted();
  const {user, userExists, refreshUser, loadingUser} = useContext(userContext);
  const {firebase} = useContext(firebaseContext);
  const {option} = useContext(optionContext);
  const {carrier} = option;

  const getCarrier = useCallback((carrier_id) => {
    return carrier.find(c => c.value === carrier_id);
  }, [carrier]);

  const confirmedCarrier = useMemo(() => {
    return getCarrier(user.carrier_id) || {}
  }, [getCarrier, user]);

  const [isValid, setValid] = useState(false);
  useEffect(() => {
    const {carrier_id, agency_id} = form;
    setValid(!userSchema(carrier).validate({...user, carrier_id, agency_id}).error);
  }, [carrier, form, user]);

  const getConfirmedCarrier = () => {
    try {
      const confirmedCarrier = JSON.parse(window.localStorage.getItem(timerKey));
      const {agency_id, carrier_id, confirmedAt} = confirmedCarrier;
      if (!agency_id || !carrier_id || !confirmedAt) return {};
      return {agency_id, carrier_id, confirmedAt};
    } catch {
      return {};
    }
  }

  useEffect(() => {
    if (!loadingUser && user && userExists) {
      setMessage('');
      const confirmedCarrier = getConfirmedCarrier();
      const {agency_id, carrier_id} = user;
      if (agency_id === confirmedCarrier.agency_id && carrier_id === confirmedCarrier.carrier_id) {
        replaceForm(confirmedCarrier);
      } else {
        const confirmedAt = (new Date()).getTime();
        const newConfirmed = {agency_id, carrier_id, confirmedAt}
        replaceForm(newConfirmed);
        window.localStorage.setItem(timerKey, JSON.stringify(newConfirmed));
      }
    }
  }, [loadingUser, user, replaceForm, setForm, userExists, prompt, setMessage])

  const handleHide = useCallback(() => {
    setPrompt(false);
  }, [setPrompt]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const {carrier_id, agency_id} = form;
    if (user.carrier_id === carrier_id && user.agency_id === agency_id) {
      setMessage('Successfully updated unit/facility.');
      setPrompt(false);
      const confirmedAt = (new Date()).getTime();
      const newConfirmed = {agency_id, carrier_id, confirmedAt}
      window.localStorage.setItem(timerKey, JSON.stringify(newConfirmed));
      return;
    }
    setLoading(true);
    setMessage('');
    firebase.getToken().then(token => {
      return update(token, {...user, carrier_id, agency_id});
    }).then(() => {
      setMessage('Successfully updated unit/facility.');
      setPrompt(false);
      const confirmedAt = (new Date()).getTime();
      const newConfirmed = {agency_id, carrier_id, confirmedAt}
      window.localStorage.setItem(timerKey, JSON.stringify(newConfirmed));
    }).catch((e) => {
      const {response} = e;
      if (response.status === 400) {
        setMessage(response.data[0].message);
      }
    }).finally(() => {
      if (isMounted()) {
        setLoading(false);
        refreshUser();
      }
    });
  }, [setLoading, setMessage, firebase, form, user, refreshUser, isMounted]);

  const promptTime = useMemo(() => {
    let {shift_time, shift_max, shift_min} = confirmedCarrier;
    if (!shift_time && !shift_max && !shift_min) return {};
    let carrierUpdated = new Date(form.confirmedAt);
    // for testing
    /*
    shift_max = 24;
    shift_min = 2;
    shift_time = "9:00:00";
    carrierUpdated = new Date('Nov 27 2023 11:43:30 GMT-0600')//*/
    let nextMin, nextMax, nextTime;
    try {
      nextMax = date.addHours(carrierUpdated, shift_max);
    } catch {}
    try {
      nextMin = date.addHours(carrierUpdated, shift_min);
    } catch {
      nextMin = carrierUpdated;
    }
    try {
      nextTime = dateNextTime(shift_time, nextMin.getTime());
    } catch {}

    if ((+nextMax && +nextTime && nextTime > nextMax) || !+nextTime) {
      nextTime = nextMax;
    }
    return {warn: nextMin, prompt: nextTime};
  }, [confirmedCarrier, form]);

  const getRemainingTime = useCallback(() => {
    const {warn, prompt} = promptTime;
    const now = new Date();
    return {
      updated: dateWithAgo(form.confirmedAt),
      showWarn: +prompt && +warn && now > warn,
      forcePrompt: +prompt && now > prompt,
      warn: +prompt && relativeTime(now, prompt),
    }
  }, [promptTime, form]);
  const [timeDisplay, setTimeDisplay] = useState(getRemainingTime());
  const updateCountdown = useCallback(() => {
    setTimeDisplay(getRemainingTime());
  }, [setTimeDisplay, getRemainingTime]);
  useInterval(updateCountdown, 1234);

  if (!userExists) return null;

  return <>
    <Modal className={`modal-${variant}`} show={prompt} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title className="font-weight-bold display-4">Confirm Unit/Facility</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="font-weight-bold font-size-h6">Updated:</span>
        <span className="label label-xl label-light-primary label-inline m-2">{timeDisplay.updated}</span><br/>
        <Form className="form" autoComplete="off">
          <div className="form-group row">
            <div className="col-lg-12">{ loading || loadingUser ?
              <>Loading<Ellipsis/></> :
              saveMessage ? <>{saveMessage}</> : null
            }</div>
          </div>
          <div className="form-group row">
            <div className="col">
              <CarrierSelect
                form={form}
                setForm={setForm}
              />
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={"secondary"}
          className={'mr-3'}
          disabled={loading}
          onClick={handleHide}
        >
          {isDirty ? "Cancel" : "Close"}
        </Button>
        <Button
          variant={!isValid || loading ? "secondary" : "primary"}
          disabled={!isValid || loading}
          onClick={handleSubmit}
        >{loading ? <Spinner animation="border"/> : "Submit"}</Button>
      </Modal.Footer>
    </Modal>
    {confirmedCarrier ?
      <>
        {timeDisplay.showWarn ?
          <div style={{
            width: 0,
            height: 0,
            overflow: 'visible',
            position: 'relative',
            top: '5px',
            zIndex: 4,
          }}>
            <Flash interval={{on: 1000, off: 1500}}>
              <div className={`label label-sm label-${timeDisplay.forcePrompt ? 'danger' : 'warning'} label-inline m-2`} >
                Confirm{timeDisplay.forcePrompt ? '\xa0now' : `\xa0in\xa0${timeDisplay.warn}`}
              </div>
            </Flash>
          </div> : null}
        <Button
          className={`btn-${timeDisplay.forcePrompt ? 'light-danger' : timeDisplay.showWarn ? 'outline-warning' : 'light'} font-weight-bold `}
          onClick={() => setPrompt(true)}
        >
          <CarrierIcon section={confirmedCarrier.section}/>
          {confirmedCarrier.label}
        </Button>
      </> : null}
  </>
}
