import React, {useCallback, useMemo, useContext, useEffect, useState} from "react";
import {useIdleTimer} from 'react-idle-timer';
import {Modal} from "react-bootstrap";
import useInterval from '../../hooks/useInterval';
import {firebaseContext} from '../../provider/AuthProvider';
import {dateWithAgo, relativeTime} from '../../tools/data-convert';

export default function AutomaticLogout({
  variant='light',
  idleTimeout = 1000 * 60 * 60, // 1 hr
  promptTimeout = 1000 * 60 * 5,
  signInTimeout = 1000 * 60 * 60 * 24, // 24 hrs
}) {
  const activeKey = 'idle_last_active';
  // save either every minute, or 3 times within the idleTimeout, whichever is more frequent.
  const useActionSaveDelay = Math.min(1000 * 60, idleTimeout / 3);

  const {firebase, authUser} = useContext(firebaseContext);
  const [signInTime, setSignInTime] = useState(firebase.getSignInTime());

  const [prompt, setPrompt] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);

  const signOut = useCallback(() => {
    setLoggedOut(true);
    firebase.signOut();
  }, [setLoggedOut, firebase]);

  /**
   * Uses local storage to see how long it's been since sign in.
   * Returns false if the user is not logged in.
   */
  const signInTimedOut = useCallback(() => {
    if (!authUser) {
      return false;
    } else {
      const signInTime = firebase.getSignInTime();
      setSignInTime(signInTime);
      const now = (new Date()).getTime();
      return now > signInTime + signInTimeout;
    }
  }, [signInTimeout, setSignInTime, firebase, authUser]);

  /**
   * Uses local storage to see how long it's been since last activity.
   */
  const idleTimedOut = useCallback(() => {
    const now = (new Date()).getTime();
    const savedActive = parseInt(window.localStorage.getItem(activeKey) || '');
    if (isNaN(savedActive)) return false;
    return now > savedActive + idleTimeout + promptTimeout;
  }, [idleTimeout, promptTimeout]);

  /**
   * onAction will always reset the idleTimer
   * through the lastActive useEffect below, which is separate because
   * it needs to be below the useIdleTimer in the scope.
   *
   * Additionally, an actual user input will save the lastActive to localstorage every so often.
   *
   */
  const [lastActive, setLastActive] = useState(parseInt(window.localStorage.getItem(activeKey) || ''));
  const onAction = useCallback(() => {
    if (!loggedOut) {
      // set lastActive (reset the idle timer)
      const now = (new Date()).getTime();
      setLastActive(now);
      // real user action (from useIdleTimer) is saved to local storage
      const savedActive = parseInt(window.localStorage.getItem(activeKey) || '');
      if (!loggedOut && (isNaN(savedActive) || now > savedActive + useActionSaveDelay)) {
        window.localStorage.setItem(activeKey, `${now}`);
      }
    }
  }, [setLastActive, loggedOut, useActionSaveDelay]);

  /**
   * Prompt will not display if it is before the signOutTime OR if the user is already logged out.
   * No Prompt will also block logging out, as
   */
  const onPrompt = useCallback(() => {
    if (!loggedOut) {
      if (signInTimedOut()) setPrompt(true);
      else {
        // if it is not time to logout, then reset the timer
        const now = (new Date()).getTime();
        setLastActive(now);
      }
    }
  }, [setPrompt, signInTimedOut, loggedOut]);

  /**
   * Sign out when timer runs out, but only if the prompt is already open.
   */
  const onIdle = useCallback(() => {
    // Close Modal Prompt
    setPrompt(false);
    // Do some idle action like log out your user
    if (signInTimedOut()) {
      signOut();
    }
  }, [setPrompt, signOut, signInTimedOut]);

  /**
   * Using times saved in local storage, logs out if it's been too long since the user opened the page.
   */
  useEffect(() => {
    if (signInTimedOut() && idleTimedOut()) {
      signOut();
    }
  }, [signInTimedOut, idleTimedOut, signOut]);

  const {start, getRemainingTime} = useIdleTimer({
    onIdle,
    onPrompt,
    onAction,
    timeout: idleTimeout,
    promptTimeout,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false
  });

  /**
   * If the prompt is open, then restart the timer. Otherwise, no effect because the prompt is already closed.
   */
  const onHide = useCallback(() => {
    setPrompt(false);
    start();
  }, [start, setPrompt]);

  /**
   * If lastActive is updated in any way (through setLastActive) then the prompt needs to be hidden.
   */
  useEffect(() => {
    onHide();
  }, [lastActive, onHide]);

  /**
   * this object displays countdown text for modal
   */
  const [countdown, setCountdown] = useState(getRemainingTime());
  const updateCountdown = useCallback(() => {
    setCountdown(getRemainingTime());
  }, [setCountdown, getRemainingTime]);
  useInterval(updateCountdown, 1234);

  const display = useMemo(() => {
    const cd = (new Date()).getTime() - (countdown || 0);
    return {
      countdown: relativeTime(new Date(cd)),
      active: dateWithAgo(lastActive),
      signIn: dateWithAgo(signInTime)
    };
  }, [countdown, lastActive, signInTime]);

  return <>
    <Modal className={`modal-${variant}`} show={prompt} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="font-weight-bold display-4">Still There?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="font-weight-bold font-size-h6"> You will be <b>signed out</b> in {display.countdown}<br/></span>
        <span className="font-weight-bold font-size-h6">Sign in time:</span>
        <span className="label label-xl label-light-primary label-inline m-2">{display.signIn}</span><br/>
        <span className="font-weight-bold font-size-h6">Last activity:</span>
        <span className="label label-xl label-light-primary label-inline m-2">{display.active}</span>
      </Modal.Body>
      <Modal.Footer><button className={`btn btn-secondary font-weight-bold px-9 py-4 my-1`} onClick={onHide}>Close</button></Modal.Footer>
    </Modal>
    <Modal className={`modal-${variant}`} show={loggedOut} onHide={() => setLoggedOut(false)}>
      <Modal.Header closeButton>
        <Modal.Title className="font-weight-bold display-4">You have been signed out.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="font-weight-bold font-size-h6">Sign in time:</span>
        <span className="label label-xl label-light-primary label-inline m-2">{display.signIn}</span><br/>
        <span className="font-weight-bold font-size-h6">Last activity:</span>
        <span className="label label-xl label-light-primary label-inline m-2">{display.active}</span>
      </Modal.Body>
      <Modal.Footer><button className={`btn btn-secondary font-weight-bold px-9 py-4 my-1`} onClick={() => setLoggedOut(false)}>Close</button></Modal.Footer>
    </Modal>
  </>;
}
