import React from "react";
//import {LayoutSplashScreen} from "../_metronic/layout";
import useUser from "../hooks/useUser";

export const userContext = React.createContext();

const UserProvider = (props) => {
  const [user, userExists, refreshUser, loadingUser] = useUser();
  //if (loadingUser) return <LayoutSplashScreen/>
  return (
    <userContext.Provider
      value={{user, userExists, refreshUser, loadingUser}}
    >
      {props.children}
    </userContext.Provider>
  );
};

export default UserProvider;
