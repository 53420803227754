import React, {useState} from "react";
import {Fade} from 'react-bootstrap';
import PropTypes from 'prop-types';
import useIsMounted from '../../hooks/useIsMounted';

export default function Flash ({interval, children}) {
  const on = () => interval.on || interval / 2;
  const off = () => interval.off || interval / 2;
  const [open, setOpen] = useState(true);
  const isMounted = useIsMounted();

  return <Fade
    appear={true}
    in={open}
    onEntering={() => {
      setTimeout(() => {
        if (isMounted()) setOpen(false);
      }, on());
    }}
    onExiting={()=>{
      setTimeout(() => {
        if (isMounted()) setOpen(true);
      }, off());
    }}
  >
    <div>{children}</div>
  </Fade>
}
Flash.propTypes = {
  children: PropTypes.node,
  interval: PropTypes.oneOfType([
    PropTypes.shape({
      on: PropTypes.number,
      off: PropTypes.number
    }),
    PropTypes.number
  ]),
};
Flash.defaultProps = {
  interval: {
    on: 800,
    off: 200,
  }
};
