import React, {useCallback, useContext, useState} from "react";
import {Modal} from "react-bootstrap";
import PropTypes from "prop-types";

export const ModalContext = React.createContext(console.log);
export const useModal = () => useContext(ModalContext);

export function ModalContextProvider (props) {
  const [modalHeader, setHeader] = useState("");
  const [modalBody, setBody] = useState("");
  const [modalFooter, setFooter] = useState(null);
  const [modalShow, setShow] = useState(false);
  const [modalOnClose, setOnClose] = useState(null);
  const [modalVariant, setVariant] = useState(null);
  const showModal = useCallback((header, body, onClose, footer, variant) => {
    if (onClose === undefined) onClose = () => setShow(false);
    if (footer === undefined) footer = <button className={`btn btn-secondary font-weight-bold px-9 py-4 my-3`} onClick={onClose}>Close</button>;
    if (variant === undefined) variant = "light";
    if (header && body) {
      setHeader(header);
      setBody(body);
      setFooter(footer);
      setVariant(variant);
      setOnClose(() => () => onClose());
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);

  return <>
    <Modal className={`modal-${modalVariant}`} show={modalShow} onHide={modalOnClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody}</Modal.Body>
      <Modal.Footer>{modalFooter}</Modal.Footer>
    </Modal>

    <ModalContext.Provider value={showModal}>
      {props.children}
    </ModalContext.Provider>
  </>
}

ModalContextProvider.propTypes = {
  children: PropTypes.node,
}

