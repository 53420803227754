import {toAbsoluteUrl} from "../routes/Routes";
import axios from "axios";

export async function getAction (firebase_token) {
  return axios({
    method: 'get',
    url: toAbsoluteUrl(`/api/readAction`),
    headers: {'authorization': `Bearer ${firebase_token}`}
  });
}

export async function getAgency (firebase_token) {
  return axios({
    method: 'get',
    url: toAbsoluteUrl(`/api/readAgency`),
    headers: {'authorization': `Bearer ${firebase_token}`}
  });
}
export async function getCarrier (firebase_token) {
  return axios({
    method: 'get',
    url: toAbsoluteUrl(`/api/readCarrier`),
    headers: {'authorization': `Bearer ${firebase_token}`}
  });
}

export async function getPrompt (firebase_token) {
  return axios({
    method: 'get',
    url: toAbsoluteUrl(`/api/readPrompt`),
    headers: {'authorization': `Bearer ${firebase_token}`}
  });
}
