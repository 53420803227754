import React from 'react';

export default function CarrierIcon ({section}) {
  switch (section) {
  case 'Aircraft': case 'Aircraft out of TSA-P':
    return <i className={'fa fa-helicopter'}></i>
  case 'EMS':
    return <i className={'fa fa-ambulance'}></i>
  case 'Hospitals':
    return <i className={'fa fa-hospital-alt'}></i>
  case 'Supply':
    return <i className={'fa fa-hand-holding-medical'}></i>
  default:
    return null;
  }
}